import application from './application/application'
import components from './components/components'
import {waitForChangesApplied} from './privates/util'
import controllers from './controllers/controllers'
import fonts from './fonts/fonts'
import history from './history/history'
import info from './info/info'
import language from './language/language'
import menu from './menu/menu'
import pages from './pages/pages'
import pagesGroup from './pagesGroup/pagesGroup'
import routers from './routers/routers'
import siteSegments from './siteSegments/siteSegments'
import siteMembers from './siteMembers/siteMembers'
import theme from './theme/theme'
import tpa from './tpa/tpa'
import vfs from './vfs/vfs'
import mobile from './mobile/mobile'
import responsiveLayout from './responsiveLayout/responsiveLayout'
import accessibility from './accessibility/accessibility'
import account from './account/account'
import errors from './errors/errors'
import wixapps from './wixApps/wixapps'
import environment from './environment/environment'
import transactions from './transactions/transactions'
import permissions from './permissions/permissions'

function save(documentServices) {
  return new Promise((resolve, reject) => {
    documentServices.save(
      () => resolve(),
      (error) => reject(error)
    )
  })
}

export {
  waitForChangesApplied,
  save,
  application,
  components,
  controllers,
  history,
  info,
  language,
  menu,
  pages,
  pagesGroup,
  routers,
  siteSegments,
  siteMembers,
  fonts,
  theme,
  tpa,
  vfs,
  mobile,
  responsiveLayout,
  accessibility,
  account,
  errors,
  wixapps,
  environment,
  transactions,
  permissions,
}
