import {runInContext, promiseApplied} from '../privates/util'

function add(documentServices, appData, token, {languageCode}) {
  runInContext(appData.appDefinitionId, documentServices, () =>
    documentServices.language.add(languageCode)
  )
  return promiseApplied(documentServices)
}

function setCurrent(documentServices, appData, token, {languageCode}) {
  return runInContext(appData.appDefinitionId, documentServices, () =>
    documentServices.language.current.set(languageCode)
  )
}

function getCurrent(documentServices, appData) {
  return runInContext(appData.appDefinitionId, documentServices, () =>
    documentServices.language.current.get()
  )
}

function getComponentTranslations(
  documentServices,
  appData,
  token,
  {componentRef}
) {
  return documentServices.language.component.getTranslations(componentRef)
}

function isMultilingualEnabled(documentServices) {
  return documentServices.language.multilingual.isEnabled()
}

function getOriginal(documentServices) {
  return documentServices.language.original.get()
}

function getTranslationLanguages(documentServices) {
  return documentServices.language.getFull()
}

function setLanguages(documentServices, languagesPayload) {
  return documentServices.language.init(languagesPayload)
}

function removeLanguage(documentServices, appData, token, languageCode) {
  return runInContext(appData.appDefinitionId, documentServices, () =>
    documentServices.multilingual.languages.remove(languageCode)
  )
}

function removeAllLanguages(documentServices, appData) {
  return runInContext(appData.appDefinitionId, documentServices, () =>
    documentServices.multilingual.languages.removeAll()
  )
}

function componentDataRemove(
  documentServices,
  appData,
  token,
  languageCode,
  componentPointer
) {
  return runInContext(appData.appDefinitionId, documentServices, () =>
    documentServices.multilingual.components.data.remove(
      languageCode,
      componentPointer
    )
  )
}

export default {
  add,
  current: {
    set: setCurrent,
    get: getCurrent,
  },
  component: {
    getTranslations: getComponentTranslations,
    data: {
      remove: componentDataRemove,
    },
  },
  original: {
    get: getOriginal,
  },
  setLanguages,
  getTranslationLanguages,
  autoTranslate: () => {
    throw new Error('not implemented for this editor')
  },
  multilingual: {
    isEnabled: isMultilingualEnabled,
    languages: {
      remove: removeLanguage,
      removeAll: removeAllLanguages,
    },
  },
}
