import {waitForChangesApplied} from '../privates/util'

function add(documentServices, appData, token, {label}) {
  return new Promise((resolve) =>
    waitForChangesApplied(documentServices, () => {
      documentServices.history.add(label)
      resolve()
    })
  )
}

export default {
  add,
}
