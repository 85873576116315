import {navigateTo} from './pageNavigation'
import _ from 'lodash'
import {runInContext, waitForChangesApplied} from '../privates/util'
import {resolveOption} from '../../../utils/utils'

const addPopup = (
  documentServices,
  appData,
  token,
  {title, definition, shouldNavigateToPage = true}
) => {
  const popupRef = runInContext(appData.appDefinitionId, documentServices, () =>
    documentServices.pages.popupPages.add(title, definition)
  )
  return shouldNavigateToPage
    ? navigateTo(
        documentServices,
        appData,
        token,
        {pageRef: popupRef},
        popupRef
      )
    : popupRef
}

const addConnectedPopup = (
  documentServices,
  appData,
  token,
  {
    title,
    definition,
    controllerType,
    popupRole,
    shouldNavigateToPage = true,
    appDefinitionId,
  }
) =>
  new Promise((resolve) => {
    runInContext(appData.appDefinitionId, documentServices, () => {
      const pageRef = documentServices.pages.popupPages.add(title, definition)
      const appDefId = resolveOption(
        appData,
        {appDefinitionId},
        'appDefinitionId',
        {isRequired: true}
      )
      const controllerStructure = _.defaultsDeep(
        {
          data: {
            controllerType,
            name: controllerType,
            applicationId: appDefId,
          },
        },
        documentServices.components.buildDefaultComponentStructure(
          'platform.components.AppController'
        )
      )
      const controllerRef = documentServices.components.add(
        pageRef,
        controllerStructure
      )
      waitForChangesApplied(documentServices, () => {
        const popupChildrenRefs = documentServices.deprecatedOldBadPerformanceApis.components.getChildren(
          pageRef
        )
        documentServices.platform.controllers.connections.connect(
          popupChildrenRefs[0],
          controllerRef,
          popupRole,
          {},
          true
        )
        resolve(
          shouldNavigateToPage
            ? navigateTo(documentServices, appData, token, {pageRef}, pageRef)
            : pageRef
        )
      })
    })
  })

const open = (documentServices, appData, token, {popupRef}) => {
  const popupId = _.get(popupRef, 'id')
  if (popupId) {
    runInContext(appData.appDefinitionId, documentServices, () =>
      documentServices.pages.popupPages.open(popupId)
    )
  }
}

const close = (documentServices, appData) => {
  runInContext(appData.appDefinitionId, documentServices, () =>
    documentServices.pages.popupPages.close()
  )
}

const isPopupOpened = (documentServices) =>
  documentServices.pages.popupPages.isPopupOpened()

const getApplicationPopups = (
  documentServices,
  appData,
  token,
  {appDefinitionId} = {}
) => {
  const appDefId = resolveOption(
    appData,
    {appDefinitionId},
    'appDefinitionId',
    {isRequired: false}
  )
  return appDefId
    ? documentServices.pages.popupPages
        .getDataList()
        .filter(({managingAppDefId}) => managingAppDefId === appDefId)
    : []
}

export {
  addPopup,
  addConnectedPopup,
  open,
  close,
  isPopupOpened,
  getApplicationPopups,
}
