import {runInContext} from '../privates/util'

function showHiddenComp(documentServices, appData, token, {componentRef}) {
  const pageId = documentServices.pages.getFocusedPageId()
  runInContext(appData.appDefinitionId, documentServices, () =>
    documentServices.mobile.hiddenComponents.show(componentRef.id, pageId)
  )
}

export default {
  hiddenComponents: {
    show: showHiddenComp,
  },
}
