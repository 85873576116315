function getCustomNoPermissionsPageId(documentServices /*, token*/) {
  return documentServices.siteMembers.getCustomNoPermissionsPageId()
}

function getCustomSignInPageId(documentServices /*, token*/) {
  return documentServices.siteMembers.getCustomSignInPageId()
}

function getCustomSignupPageId(documentServices /*, token*/) {
  return documentServices.siteMembers.getCustomSignupPageId()
}

function setCustomNoPermissionsPageId(documentServices, token, pageId) {
  return documentServices.siteMembers.setCustomNoPermissionsPageId(pageId)
}

function setCustomSignupPageId(documentServices, token, pageId) {
  return documentServices.siteMembers.setCustomSignupPageId(pageId)
}

function setCustomSignInPageId(documentServices, token, pageId) {
  return documentServices.siteMembers.setCustomSignInPageId(pageId)
}

function getPrivacyNoteType(documentServices) {
  return documentServices.siteMembers.getPrivacyNoteType()
}

const privacyNoteTypes = ['CHECKBOX', 'NOTE']

function setPrivacyNoteType(documentServices, privacyNoteType) {
  if (privacyNoteTypes.includes(privacyNoteType)) {
    return documentServices.siteMembers.setPrivacyNoteType(privacyNoteType)
  }

  const availablePrivacyNoteList = privacyNoteTypes.join(', ')

  throw new Error(
    `Invalid privacy note type. Available options: ${availablePrivacyNoteList}`
  )
}

export default {
  getCustomSignInPageId,
  getCustomSignupPageId,
  setCustomSignInPageId,
  setCustomSignupPageId,
  getCustomNoPermissionsPageId,
  setCustomNoPermissionsPageId,
  getPrivacyNoteType,
  setPrivacyNoteType,
}
