import _ from 'lodash'
import {isOperationAllowedInContext, runInContext} from '../privates/util'
import {isExtensionContext, resolveOption} from '../../../utils/utils'
import {getAppType, constants} from './tpaUtils'

const getAppDefinitionId = (documentServices, compRef) => {
  // The appDefinitionId for platform apps is saved in their data as applicationId. Therefore if it is a platform app, we need to return the applicationId.
  const {appDefinitionId, applicationId} =
    documentServices.components.data.get(compRef) || {}
  return appDefinitionId || applicationId
}

function isAppSectionInstalled(
  documentServices,
  appData,
  token,
  {sectionId, appDefinitionId} = {}
) {
  return new Promise((resolve, reject) => {
    if (!sectionId) {
      reject(new Error('options must contain sectionId'))
      return
    }

    let applicationId = appData.applicationId
    if (isExtensionContext(appData) && !appDefinitionId) {
      reject(new Error('options must contain appDefinitionId'))
      return
    }
    if (appDefinitionId) {
      const otherAppData = documentServices.tpa.app.getDataByAppDefId(
        appDefinitionId
      )
      applicationId = _.get(otherAppData, 'applicationId')
    }

    resolve(
      documentServices.tpa.section.isSectionInstalledByTpaPageId(
        applicationId,
        sectionId
      )
    )
  })
}

function isApplicationInstalled(
  documentServices,
  appData,
  token,
  {appDefinitionId} = {}
) {
  return new Promise((resolve, reject) => {
    if (!appDefinitionId) {
      reject(new Error('options must contain appDefinitionId'))
      return
    }
    const isAppInstalled = documentServices.tpa.app.isInstalled(appDefinitionId)
    resolve(isAppInstalled)
  })
}

function refreshApp(documentServices, appData, {applicationId} = {}) {
  const appId = resolveOption(appData, {applicationId}, 'applicationId', {
    isRequired: true,
  })
  const appComps = documentServices.tpa.app.getAllCompsByApplicationId(appId)
  return runInContext(appData.appDefinitionId, documentServices, () =>
    documentServices.tpa.app.refreshApp(appComps)
  )
}

function getDataByAppDefId(documentServices, appData, token, appDefinitionId) {
  return documentServices.tpa.app.getDataByAppDefId(appDefinitionId)
}

function getAllCompsByApplicationId(
  documentServices,
  appData,
  token,
  applicationId
) {
  return documentServices.tpa.app.getAllCompsByApplicationId(applicationId)
}

function canApplicationBeInstalledSeveralTimes(marketData) {
  if (getAppType(marketData) !== constants.APP.TYPE.WIDGET) {
    return false
  }

  return marketData?.widgets?.every((widget) => !widget.addOnlyOnce)
}

function isPlatformOnlyProvision(options) {
  // at this moment only ['PLATFORM'] option is supported
  if (options.componentTypes?.length !== 1) {
    return false
  }

  return options.componentTypes.includes(constants.COMPONENT.TYPE.PLATFORM)
}

function addApplication(documentServices, appData, token, options) {
  return new Promise((resolve, reject) => {
    const params = _.clone(options)
    if (!_.get(options, 'appDefinitionId')) {
      reject(new Error('options must contain appDefinitionId'))
      return
    }

    if (
      options.appDefinitionId ===
      documentServices.platform.editorApps.WIX_CODE.appDefId
    ) {
      documentServices.wixCode.provision({
        onSuccess: resolve,
        onError: reject,
      })
      return
    }

    documentServices.tpa.appMarket
      .getDataAsync(options.appDefinitionId)
      .then((marketData) => {
        if (!marketData || marketData.error) {
          reject(
            new Error(
              `Application with ${options.appDefinitionId} appDefinitionId do not exist.`
            )
          )
          return
        }
        const isWixTPA = marketData.by === 'Wix'
        if (isWixTPA) {
          params.callback = function (data) {
            if (data.onError) {
              reject(new Error(data.onError))
            } else {
              appData = documentServices.tpa.app.getDataByAppDefId(
                options.appDefinitionId
              )
              if (data.page) {
                const pageData = documentServices.pages.data.get(data.page.id)
                resolve({
                  instanceId: _.get(appData, 'instanceId'),
                  pageRef: data.page,
                  pageUriSEO: _.get(pageData, 'pageUriSEO'),
                  title: _.get(pageData, 'title'),
                })
              } else {
                resolve({
                  instanceId: _.get(appData, 'instanceId'),
                })
              }
            }
          }

          const isAppInstalled = documentServices.tpa.app.isInstalled(
            options.appDefinitionId
          )

          if (
            isAppInstalled &&
            !canApplicationBeInstalledSeveralTimes(marketData)
          ) {
            reject(new Error('Application already installed.'))
            return
          }

          runInContext(appData.appDefinitionId, documentServices, () => {
            const provisionOnlyPlatformPart =
              isPlatformOnlyProvision(options) &&
              documentServices.tpa.app.hasEditorPlatformPart(marketData)
            if (
              getAppType(marketData) === constants.APP.TYPE.PLATFORM_ONLY ||
              provisionOnlyPlatformPart
            ) {
              const defaultInfo = {
                appDefinitionId: _.get(appData, 'appDefinitionId'),
              }

              const info = options.originInfo
                ? _.assign({}, options.originInfo, defaultInfo)
                : defaultInfo

              const provisioningOptions = {
                origin: {
                  initiator: 'APP',
                  type: options.editorType,
                  info,
                },
              }

              if (options.sourceTemplateId) {
                provisioningOptions.sourceTemplateId = options.sourceTemplateId
              }

              if (options.isSilent) {
                provisioningOptions.isSilent = true
              }

              return documentServices.platform
                .provision(options.appDefinitionId, provisioningOptions)
                .then(resolve, reject)
            }
            return documentServices.tpa.app.add(options.appDefinitionId, params)
          })
        } else {
          reject(new Error("Can't add a non Wix application"))
        }
      })
  })
}

function addComponent(documentServices, appData, token, options) {
  return new Promise((resolve, reject) => {
    const params = _.clone(options)
    if (!_.get(options, 'componentType')) {
      reject(new Error('options must contain componentType'))
      return
    }

    if (!options.appDefinitionId) {
      if (isExtensionContext(appData)) {
        reject(new Error('options must contain appDefinitionId'))
        return
      }
      params.appDefinitionId = appData.appDefinitionId
    }

    if (options.componentType === 'WIDGET') {
      if (!_.isObject(options.widget)) {
        reject(new Error('options must contain a widget object'))
        return
      }
      params.widget.tpaWidgetId = options.widget.widgetId
    } else if (options.componentType === 'PAGE') {
      if (!_.isObject(options.page)) {
        reject(new Error('options must contain a page object'))
        return
      }
      if (options.page.isHidden && !_.isBoolean(options.page.isHidden)) {
        reject(new Error('isHidden param should be of type boolean'))
        return
      }
    }

    params.callback = function (data) {
      if (data.onError) {
        reject(new Error(data.onError))
      } else if (options.componentType === 'WIDGET') {
        resolve({compId: data.comp.id})
      } else {
        const pageData = documentServices.pages.data.get(data.page.id)
        resolve({
          compId: data.sectionId,
          pageRef: data.page,
          pageUriSEO: _.get(pageData, 'pageUriSEO'),
          title: _.get(pageData, 'title'),
        })
      }
    }
    runInContext(appData.appDefinitionId, documentServices, () =>
      documentServices.tpa.comp.add(params)
    )
  })
}

function deleteApp(documentServices, appData, {applicationId} = {}) {
  return new Promise((resolve) => {
    const appId = resolveOption(appData, {applicationId}, 'applicationId', {
      isRequired: true,
    })
    runInContext(appData.appDefinitionId, documentServices, () =>
      documentServices.tpa.app.delete(appId)
    )
    documentServices.waitForChangesApplied(resolve)
  })
}

function setStyleParams(documentServices, appData, token, options) {
  return new Promise((resolve, reject) => {
    if (!options || !options.compRef) {
      reject('options must include compRef property')
      return
    }

    if (!options || !options.styleParams || !_.isArray(options.styleParams)) {
      reject(
        'options must include styleParams property and styleParams must be an array'
      )
      return
    }

    const appDefinitionId = getAppDefinitionId(
      documentServices,
      options.compRef
    )
    if (!appDefinitionId) {
      reject('could not find component data for the given component ref')
      return
    }

    if (!isOperationAllowedInContext(appData, appDefinitionId)) {
      reject(
        `It is not allowed to set style params for other applications, application ${appData.appDefinitionId} cannot set applications ${appDefinitionId} components style params`
      )
      return
    }

    runInContext(appData.appDefinitionId, documentServices, () =>
      documentServices.tpa.style.setCompStyleParam(
        options.compRef,
        options.styleParams,
        () => resolve()
      )
    )
  })
}

function getStyleParams(documentServices, appData, token, options) {
  return new Promise((resolve, reject) => {
    if (!options || !options.compRef) {
      reject('options must include compRef property')
      return
    }

    const appDefinitionId = getAppDefinitionId(
      documentServices,
      options.compRef
    )
    if (!appDefinitionId) {
      reject('could not find component data for the given component ref')
      return
    }

    if (!isOperationAllowedInContext(appData, appDefinitionId)) {
      reject(
        `It is not allowed to get the style params from other applications, application ${appData.appDefinitionId} cannot get applications ${appDefinitionId} components style params`
      )
      return
    }

    const {style} = documentServices.tpa.style.getByComp(options.compRef) || {}
    resolve(style)
  })
}

function getSiteTextPresets(documentServices, appData, token, options) {
  if (!options || !options.compRef) {
    throw new Error('options must include compRef property')
  }

  const {siteTextPresets} = documentServices.tpa.style.get(options.compRef.id)

  return siteTextPresets
}

function getSiteColors(documentServices, appData, token, options) {
  if (!options || !options.compRef) {
    throw new Error('options must include compRef property')
  }

  const {siteColors} = documentServices.tpa.style.get(options.compRef.id)

  return siteColors
}

const publicDataHandler = (resolve, reject) => (data) => {
  if (data.error) {
    reject(new Error(data.error.message))
    return
  }

  resolve(data)
}

function getPublicData(documentServices, appData, token, options) {
  return new Promise((resolve, reject) => {
    if (!options || !options.compRef) {
      reject(new Error('options must include compRef property'))
      return
    }

    const {applicationId} =
      documentServices.components.data.get(options.compRef, null, true) || {}

    if (applicationId === undefined) {
      reject(
        new Error('could not find component data for the given component ref')
      )
      return
    }

    documentServices.tpa.data.getPublicData(
      applicationId,
      options.compRef,
      publicDataHandler(resolve, reject)
    )
  })
}

function setPublicDataValue(documentServices, appData, token, options) {
  return new Promise((resolve, reject) => {
    if (!options || !options.compRef) {
      reject(new Error('options must include compRef property'))
      return
    }

    if (!options.key) {
      reject(new Error('options must include key property'))
      return
    }

    const appDefinitionId = getAppDefinitionId(
      documentServices,
      options.compRef
    )
    if (!appDefinitionId) {
      reject(
        new Error('could not find component data for the given component ref')
      )
      return
    }

    if (!isOperationAllowedInContext(appData, appDefinitionId)) {
      reject(
        new Error(
          `it is not allowed to set the public data from other applications, application ${appData.appDefinitionId} cannot set applications ${appDefinitionId} public data`
        )
      )
      return
    }

    runInContext(appData.appDefinitionId, documentServices, () =>
      documentServices.tpa.data.set(
        options.compRef,
        options.key,
        options.value,
        options.scope || 'COMPONENT',
        publicDataHandler(resolve, reject)
      )
    )
  })
}

function removePublicDataValue(documentServices, appData, token, options) {
  return new Promise((resolve, reject) => {
    if (!options || !options.compRef) {
      reject(new Error('options must include compRef property'))
      return
    }

    if (!options.key) {
      reject(new Error('options must include key property'))
      return
    }

    const appDefinitionId = getAppDefinitionId(
      documentServices,
      options.compRef
    )
    if (!appDefinitionId) {
      reject(
        new Error('could not find component data for the given component ref')
      )
      return
    }

    if (!isOperationAllowedInContext(appData, appDefinitionId)) {
      reject(
        new Error(
          `it is not allowed to remove the public data from other applications, application ${appData.appDefinitionId} cannot remove applications ${appDefinitionId} public data`
        )
      )
      return
    }

    runInContext(appData.appDefinitionId, documentServices, () =>
      documentServices.tpa.data.remove(
        options.compRef,
        options.key,
        options.scope || 'COMPONENT',
        publicDataHandler(resolve, reject)
      )
    )
  })
}

function getPageRefByTPAPageId(documentServices, appData, token, options) {
  const pagesData = documentServices.pages.getPagesData()

  const tpaPageData = pagesData.find(
    (data) => data?.tpaPageId === options.tpaPageId
  )

  if (tpaPageData) {
    return documentServices.pages.getReference(tpaPageData.id)
  }
}

function notImplementedInEditor(name) {
  return () => {
    console.warn(
      `Editor SDK call is not implemented for this editor, method: ${name}`
    )
  }
}

export default {
  isAppSectionInstalled,
  isApplicationInstalled,
  setStyleParams,
  getStyleParams,
  getSiteTextPresets,
  getSiteColors,
  getPageRefByTPAPageId,
  data: {
    getAll: getPublicData,
    set: setPublicDataValue,
    remove: removePublicDataValue,
  },
  add: {
    application: addApplication,
    component: addComponent,
  },
  app: {
    getDataByAppDefId,
    getAllCompsByApplicationId,
    refreshApp,
    delete: deleteApp,
    add: addApplication,
  },
  widgetPlugins: {
    getWidgetSlots: notImplementedInEditor(
      'document.tpa.widgetPlugins.getWidgetSlots'
    ),
    addWidgetPlugin: notImplementedInEditor(
      'document.tpa.widgetPlugins.addWidgetPlugin'
    ),
    removeWidgetPlugin: notImplementedInEditor(
      'document.tpa.widgetPlugins.removeWidgetPlugin'
    ),
  },
}
