import {isExtensionContext} from '../../../utils/utils'

const promiseApplied = (documentServices, returnValue) =>
  new Promise((resolve) => {
    documentServices.waitForChangesApplied(() => resolve(returnValue))
  })

const waitForChangesApplied = (documentServices, callback) =>
  documentServices.waitForChangesApplied(callback)

const runInContext = (appDefId, documentServices, func) =>
  documentServices.runInContext(appDefId, func)

const isOperationAllowedInContext = (appData, itemAppDefinitionId) => {
  if (isExtensionContext(appData)) {
    return true
  }

  return itemAppDefinitionId === appData.appDefinitionId
}

export {
  promiseApplied,
  runInContext,
  isOperationAllowedInContext,
  waitForChangesApplied,
}
