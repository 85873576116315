import {runInContext} from '../privates/util'
import {resolveOption} from '../../../utils/utils'

function create(documentServices, appData, token, {groupName, applicationId}) {
  const appId = resolveOption(appData, {applicationId}, 'applicationId', {
    isRequired: true,
  })
  const pagesGroupId = runInContext(
    appData.appDefinitionId,
    documentServices,
    () =>
      documentServices.pagesGroup.create({
        groupName,
        appId,
      })
  )
  return Promise.resolve(pagesGroupId)
}

function addPageToPagesGroup(
  documentServices,
  appData,
  token,
  {pagesGroupPointer, pageId}
) {
  runInContext(appData.appDefinitionId, documentServices, () =>
    documentServices.pagesGroup.addPageToPagesGroup(pagesGroupPointer, pageId)
  )
}

function getById(documentServices, appData, token, {pagesGroupId}) {
  return documentServices.pagesGroup.getById(pagesGroupId)
}

function getByGroupName(documentServices, appData, token, {groupName}) {
  return documentServices.pagesGroup.getByGroupName(groupName)
}

function getAll(documentServices, appData, token, {applicationId} = {}) {
  const appId = resolveOption(appData, {applicationId}, 'applicationId', {
    isRequired: true,
  })
  return documentServices.pagesGroup.getAll(appId)
}

function remove(documentServices, appData, token, {pagesGroupPointer}) {
  runInContext(appData.appDefinitionId, documentServices, () =>
    documentServices.pagesGroup.remove(pagesGroupPointer)
  )
}

export default {
  create,
  addPageToPagesGroup,
  getById,
  getByGroupName,
  getAll,
  remove,
}
